import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

var showdown = require("showdown")

export default ({ data, pageContext, location }) => {
  var post
  var language

  if (pageContext && pageContext.language) {
    language = pageContext.language

    post = data.allPagesJson.nodes[0]
  } else {
    post = data.allPagesJson.nodes[0]
  }

  const converter = new showdown.Converter()
  var topTextHtml = converter.makeHtml(post.topText)
  var bodyHtml = converter.makeHtml(post.body)

  function createTopTextHtml() {
    return { __html: topTextHtml }
  }

  function createBodyHtml() {
    return { __html: bodyHtml }
  }

  if (post.hasTopSection === true) {
    return (
      <SharedStateProvider>
        <Layout language={language}>
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            pathname={location.pathname}
            schemaPage={post.schemaPage ? post.schemaPage : null}
          />
          <div className="joshua-tree-content">
            <div className="columns">
              <div className="column">
                <div className="columns top-section color-back text-section is-mobile">
                  <div className="column is-2"></div>
                  <div className="column is-20">
                    <div>
                      <h1>{post.name}</h1>
                      <div dangerouslySetInnerHTML={createTopTextHtml()}></div>
                    </div>
                  </div>
                  <div className="column is-2"></div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="columns body-section text-section is-mobile">
                  <div className="column is-2"></div>
                  <div className="column is-20">
                    <div>
                      <div dangerouslySetInnerHTML={createBodyHtml()}></div>
                    </div>
                    <div className="column is-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </SharedStateProvider>
    )
  } else {
    return (
      <SharedStateProvider>
        <Layout language={language}>
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            pathname={location.pathname}
            schemaPage={post.schemaPage ? post.schemaPage : null}
          />
          <div className="joshua-tree-content">
            <div className="columns">
              <div className="column">
                <div className="columns top-section is-mobile white-back">
                  <div className="column is-2"></div>
                  <div className="column is-20">
                    <div>
                      <h1>{post.name}</h1>
                      <div dangerouslySetInnerHTML={createBodyHtml()}></div>
                    </div>
                    <div className="column is-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </SharedStateProvider>
    )
  }
}

// allSpanishPagesJson(filter: { title: { eq: $title } }) {
//   edges {
//     node {
//       title
//       name
//       metaTitle
//       metaDescription
//       hasTopSection
//       topText
//       body
//       manual_file
//     }
//   }
// }

export const pageQuery = graphql`
  query($title: String!) {
    allPagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        name
        metaTitle
        metaDescription
        hasTopSection
        topText
        body
        manual_file
      }
    }
  }
`
